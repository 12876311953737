import React, { useState } from "react"
import { Link, navigateTo } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../success.css"
const Success = () => {
  const [step, setStep] = useState(1)
  const [snapchatname, setSnapchatname] = useState("")
  const [hasError, setHasError] = useState("")
  const continueStep = () => {
    if (snapchatname.length > 2) {
      setStep(2)
    } else {
      setHasError("*This snapchat name is invalid")
    }
  }
  return (
    <Layout>
      <SEO title={`Congrats on your join! `} />
      <div className="main">
        <div className="container-success">
          {step === 1 ? (
            <div className="aStep">
              <h1>
                Step 2:{" "}
                <small>
                  {" "}
                  ENTER YOUR SNAPCHAT USERNAME (
                  <Link to="/how-to">what's this for?</Link>)
                </small>
              </h1>
              {hasError ? <h3 className="error">{hasError}</h3> : null}
              <div className="successInput">
                <input
                  type="text"
                  value={snapchatname}
                  onChange={e => setSnapchatname(e.target.value)}
                  placeholder="Your Snapchat Username"
                  onKeyDown={e =>
                    e.key === "Enter" && snapchatname !== ""
                      ? continueStep()
                      : null
                  }
                />
              </div>
              <div className="continue">
                <button className="successBtn skip" onClick={() => setStep(2)}>
                  Skip
                </button>
                <button className="successBtn" onClick={continueStep}>
                  Continue
                </button>
              </div>
            </div>
          ) : null}
          {step === 2 ? (
            <div className="aStep">
              <h1>
                Step 3: <small>LOGIN TO THE MEMBERS AREA!</small>
              </h1>
              <p>
                when you login you will see the private snapchat of the friend
                you joined!
              </p>
              <button
                className="successBtn oneHundo"
                onClick={() =>
                  (window.location.href = "https://addfriends.com/vip")
                }
              >
                Login!
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </Layout>
  )
}

export default Success
